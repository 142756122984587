@import "src/style/variables";

.accordionListHeader {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: sticky;

  h2 {
    margin-left: 2rem;
  }
}

.tabs {
  display: flex;
  cursor: pointer;
  gap: 0.5rem;
  z-index: 99;
  top: 0.19rem;
  position: relative;

  * {
    cursor: pointer;
  }

  .inactive {
    background-color: $colors-background-blue;
    border-bottom: 0.2rem solid $colors-semantic-action-default;
  }

  .active {
    border-bottom: 0.25rem solid $colors-white;
  }
}

.tabContent {
  flex: 1;
}

.infoContainer {
  padding: 0 2rem;
}

.envPicker {
  display: flex;
  align-items: center;
  gap: 1rem;

  p {
    white-space: nowrap;
  }
}

.skeletonHeader span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.skeletonTop {
  display: flex;
  gap: 1rem;
}

.noScopesBox {
  margin-top: 3rem;
  padding: 2rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .noScopesImage {
    width: 70%;
  }

  .noScopesText {
    font-size: 1rem;
    color: $colors-black;
  }

  .noScopesHeader {
    color: $colors-semantic-action-default;
    font-size: 1.5rem;
  }
}
