@import "src/style/variables";
@import "src/style/mixins";

.tabsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabs {
  width: 100%;
  max-width: $content-text-width-max;
  box-sizing: border-box;
  padding: 0 2rem;
  display: flex;
  gap: 0.5rem;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  color: inherit;
  border: 0;
  background: none;
  font: inherit;
  outline: inherit;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  svg {
    height: 2rem;
    width: 2rem;
  }

  p {
    cursor: pointer;
  }

  &.active {
    background-color: $colors-background-blue;
  }

  &:focus {
    outline: 0.15rem solid $colors-interface-highlight;
  }

  &.inactive {
    box-shadow: 0.1rem -0.1rem 0.5rem lightgray;
  }

  @include respond-to("medium") {
    padding: 0.5rem 1rem;

    svg {
      height: 2.5rem;
      width: 2.5rem;
    }

    p {
      display: none;
    }
  }
}

.tabsContentContainer {
  width: 100%;
  background-color: $colors-background-blue;
  display: flex;
  justify-content: center;
}

.tabsContent {
  width: 100%;
  max-width: $content-text-width-max;
  padding: 3rem 2rem;
  text-align: left;
}

.bottomSpacing {
  margin-bottom: 2rem;
}

.orderedList {
  li {
    margin-top: 0.25rem;
  }
}

.configHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
  span {
    width: fit-content;
  }
}

.configBox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
