@import "src/style/variables";
@import "src/style/mixins";

.header {
  width: 100%;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    list-style: none;
  }
}

.content {
  width: $content-width-max;
  max-width: $content-width-max;
  height: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 2.5rem;
    height: fit-content;

    g {
      fill: $colors-interface-highlight;
    }
  }

  p {
    color: $colors-interface-info-900;
  }
}

.headerLinks {
  display: flex;
  gap: 2rem;
  font-size: 1.25rem;

  @include respond-to("small") {
    display: none;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colors-interface-highlight;
  }

  a:hover {
    border-bottom: 2px solid $colors-interface-highlight;
  }

  .active {
    border-bottom: 2px solid $colors-interface-highlight;
  }

  .inactive {
    border-bottom: none;
  }
}

.userInfo {
  display: flex;
  height: fit-content !important;
  align-items: center;

  svg {
    width: 2rem;
    height: 2rem;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.smallMenuButton {
  display: flex;
  align-items: center !important;
  justify-content: center;

  label {
    color: $colors-semantic-action-default;
  }
}

.svg {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.25rem;
  background-color: transparent;
  border-radius: 50%;
  margin-left: auto;
  fill: white;

  @include respond-to("small") {
    margin-left: 0;
  }
}

.logoutLabel button {
  color: $colors-danger-red !important;
  display: flex;
  justify-content: space-between !important;
}

.dropdownItem a, .dropdownItem button {
  display: flex;
  justify-content: space-between !important;
}
