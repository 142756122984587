@import "src/style/variables";
@import "src/style/mixins";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid gray;
  cursor: pointer;
}

.headerRow {
  * {
    cursor: pointer;
  }

  span {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;
  }
}

.contentRow {
  padding: 0 2rem 2rem 2rem;
}

.statusIcon {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-left: auto;
  height: 100%;

  svg {
    width: 2rem;
    height: 2rem;
  }
}

.buttonRow {
  margin-top: 2rem;
}

.opprettButton {
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.noClientBox {
  border-radius: 1rem;
  background-color: $colors-gray;
  width: fit-content;
  padding: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;

  p:first-child {
    margin-bottom: 0.5rem;
  }
}

.scopeInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  p,
  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.scopeInfoTop {
  display: flex;
  align-items: center;
  gap: 2rem;

  @include respond-to("medium") {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
}

.placeholderText {
  border-radius: 0.25rem;
  color: transparent;
  height: 1rem;
  width: 100%;
  background-color: $colors-gray;
}

.publicTag {
  width: fit-content !important;
  height: fit-content;
}

.desc {
  display: inline-block;
  max-width: 45rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;

  @include respond-to("medium") {
    max-width: 30rem;
  }

  @include respond-to("small") {
    max-width: 20rem;
  }
}
