@import "src/style/variables";
@import "src/style/mixins";

.container {
  border-radius: 1rem;
  padding: 2rem 0;
  background-color: $colors-lightgray;
  margin-bottom: 1rem;
}

.content {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 0 2rem;

  div p:first-child {
    font-weight: bold;
  }
}

.icon svg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;

  @include respond-to("medium") {
    height: 2rem;
    width: 2rem;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  & > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    @include respond-to("medium") {
      margin-top: 0.5rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;
    }
  }
}

.scopeFields {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.deleteButton {
  margin-left: auto;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}
