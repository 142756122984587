@import "src/style/variables";

.layout {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $colors-white;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
