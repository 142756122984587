@import "src/style/variables";

.modal {
  width: 90%;
  max-width: $content-width-max !important;
  overflow: hidden;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  box-sizing: border-box;
  width: calc(100% - (var(--fds-spacing-6) * 2));
}

.modalButtons {
  display: flex;
  align-items: center;
  margin-top: auto;
  gap: 1rem;
}

.modalContent {
  margin-top: 1rem;
  height: 25rem;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}

.modalFooter {
  height: 7rem;
}

.alert {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    display: flex;
    gap: 0.5rem;
  }

  &.err svg path {
    fill: $colors-danger-red;
  }

  &.warn svg path {
    fill: $colors-warning-orange;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.required label:after {
  content: " *";
  color: red;
}

.radioButtonLabel {
  display: flex;
  gap: 0.5rem;
}

.keyTextArea {
  textarea {
    height: 10rem;
  }
}

.step2ButtonRow {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  div {
    display: flex;
    gap: 1rem;
  }
}

.radioButtons {
  width: fit-content;
}

.successContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.responseInfo {
  border-radius: 1rem;
  background-color: $colors-gray;
  flex-basis: 0;
  flex-grow: 1;
}

.responseInfoContent {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;

  div {
    padding: 1rem 0;
    border-bottom: 1px solid lightgray;

    &:last-child {
      border-bottom: none;
    }

    p:first-child {
      font-weight: bold;
    }
  }
}

.infoFields {
  display: flex;
  gap: 3rem;
  align-items: center;

  div {
    flex: 1;
  }
}

.keyTextLabel {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.keygenArea {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.usageInfo {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;

  p {
    margin-bottom: 1rem;
  }
}

.usageButtons {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.integrationSelect {
  display: flex;
  gap: 5rem;
}

.integrationInfo {
  display: flex;
  flex-direction: column;
}

.kid {
  padding: 0.5rem !important;
  background-color: $colors-gray;
  border-radius: 0.5rem;
  border: 1px solid $colors-gray;
  color: $colors-interface-highlight !important;
  margin: 0.5rem 0 !important;
  width: fit-content;
}

.rightToLeft {
  position: absolute;
}

.rightToLeftEnter {
  transform: translateX(120%);
}

.rightToLeftEnterActive {
  transform: translateX(0);
  transition: all 1s ease;
}

.rightToLeftExit {
  transform: translateX(0);
}

.rightToLeftExitActive {
  transform: translateX(-120%);
  transition: all 1s ease;
}

.leftToRight {
  position: absolute;
}

.leftToRightEnter {
  transform: translateX(-120%);
}

.leftToRightEnterActive {
  transform: translateX(0);
  transition: all 1s ease;
}

.leftToRightExit {
  transform: translateX(0);
}

.leftToRightExitActive {
  transform: translateX(120%);
  transition: all 1s ease;
}
