@import "src/style/variables";

.selectionContainer {
  margin-bottom: 2rem;
  input {
    padding: 0.5rem !important;
    width: fit-content;
  }
}

.smallHeading {
  margin-top: 3rem !important;
  margin-bottom: 1rem !important;
}

.codeExampleBox {
  width: 100%;
  background-color: $colors-white;
  border-radius: 0.5rem;
  border: 2px solid $colors-gray;
}

.codeExampleTabs {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 2px solid $colors-gray;

  .codeExampleTab {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    padding: 1rem 2rem;
    cursor: pointer;
    outline: inherit;
    font: inherit;
    color: inherit;
    background: none;
    border: none;

    &:focus {
      outline: 0.1rem solid $colors-interface-highlight;
    }

    &.active {
      color: $colors-semantic-action-default;
    }

    &:hover::after,
    &.active::after {
      display: inline-block;
      content: "";
      height: 0.1rem;
      width: 100%;
      background-color: $colors-semantic-action-default;
    }
  }
}

.dependencies {
  width: fit-content;
  position: absolute;
  right: 1rem;
  opacity: 0.25;
  transition: opacity 0.1s linear;

  &:hover {
    opacity: 1;
  }
}

.codeExampleContent {
  position: relative;
  padding: 1rem;
}

.selectFormattedBox {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  gap: 1.5rem;

  .labelBoxBranch {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
  }

  .labelBoxTitle {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  span {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.25rem;
    }
  }
}
