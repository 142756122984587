@import "src/style/variables";

.container {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  max-width: $content-text-width-max;
}

.subheader {
  margin-top: 3rem !important;
  margin-bottom: 1rem !important;
}

.paragraph {
  margin-bottom: 2rem !important;
}
