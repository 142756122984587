@import "src/style/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 50rem !important;
  width: 100%;
  padding: 2rem;
  text-align: left;
}

.errorMessage {
  width: 100%;
}

.heading {
  display: flex;
  gap: 1rem;
  margin-top: 3rem !important;

  span {
    height: fit-content;
    text-wrap: nowrap;
  }
}

.boldText {
  font-weight: bold;
}

.svg {
  height: 4rem;
}

.loginRow {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 3rem;
}

.loginButton {
  padding: 0.5rem 1rem;
  min-width: fit-content;
}

.faq {
  margin-top: 5rem;

  label {
    cursor: pointer;
  }
}
