@import "src/style/variables";

.container {
  max-width: $content-text-width-max;
}

.subheader {
  margin-top: 3rem !important;
  margin-bottom: 1rem !important;
}

.paragraph {
  margin-bottom: 2rem !important;
}
