@import "src/style/variables";
@import "src/style/mixins";

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15rem;
}

.footerContent {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  width: $content-width-max;

  @include respond-to("small") {
    flex-direction: column;
  }
}
