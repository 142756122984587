@import "src/style/variables";

.link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $colors-black;
  font-weight: bold;
  width: fit-content;
  height: fit-content;
  padding: 0.5rem 0;
  text-decoration: none;
  border-bottom: 2px solid $colors-semantic-action-default;
}

.icon {
  width: 1rem;
  height: fit-content;
}
