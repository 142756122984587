@import "src/style/variables";
@import "src/style/mixins";

.link {
  border-bottom: none;
  margin: 2rem 0;
}

.container {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: 1px solid $colors-gray;
  border-radius: 0.3rem;
  box-shadow: 0.5rem 0.5rem 0.75rem lightgray;
  transition: transform 0.1s ease-in-out;

  &:hover {
    transform: translateY(-0.25rem);
  }

  @include respond-to("small") {
    padding: 1rem 2rem;
  }
}

.text {
  color: $colors-black;
  font-weight: bold;
  width: fit-content;
  height: fit-content;
  padding: 0.5rem 0;
  text-decoration: none;
  border-bottom: 2px solid $colors-semantic-action-default;
}

.textSection {
  display: flex;
  flex-direction: column;
}

.illustration {
  width: 7rem;
  height: fit-content !important;
  margin-left: 1rem;
}
