$colors-gray: #efefef;
$colors-lightgray: #f4f5f6;
$colors-darkgray: #d2d5d8;
$colors-interface-info-900: #022f51;
$colors-semantic-action-default: #0062ba;
$colors-interface-highlight: #1eadf7;
$colors-background-blue: #e6eff8;
$colors-white: #ffffff;
$colors-black: #000000;
$colors-danger-red: #e02e49;
$colors-warning-orange: #ff8c06;
$colors-success: #118849;

$content-width-max: 80rem;
$content-text-width-max: 60rem;

$breakpoints: (
  "tiny": 516px,
  "small": 767px,
  "medium": 992px,
  "large": 1300px,
  "wide": 1650px,
);
